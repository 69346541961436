<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  updated () {
    // 禁止浏览器后退按钮
    window.history.pushState(null, null, window.Location.href)
  }
  // watch: {
  //   '$route.path':  (val)=> {

  //   }
  // }
}
</script>>

<style >
  *{padding: 0;margin: 0;box-sizing: border-box;}
  #app,body,html{height:100%}
</style>
