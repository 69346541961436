import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 引入 ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 引入 字体
// import './assets/fonts/iconfont.css'


// 引入 ECharts
// import ECharts from 'vue-echarts/components/ECharts'
// import 'echarts/lib/chart/line'
// import 'echarts/lib/component/tooltip'
// Vue.component('chart', ECharts)


// 引入 store
// import store from './store'
// import moment from 'moment'
// Vue.prototype.$moment = moment //方便在vue组件中可以直接使用 this.$moment -> moment


// 引入mqtt
import './mq'


// 系统文件
Vue.config.productionTip = false

window.history.pushState(null, null, window.Location.href)
window.addEventListener('popstate', function () {
  window.history.forward(-1)
  window.history.pushState(null, null, window.Location.href)
})

const vue = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

export default vue
