import mqtt from 'mqtt'
import Vue from 'vue'
import { Notification } from 'element-ui'

let client = null;
let clientStore = {};
var clientId = "VUE_" + Math.random().toString(16).substr(2, 8);
var options = {
	clientId,
	clean: true,
	connectTimeout: 4000,
	username: 'emqx',
	password: 'public',
	reconnectPeriod: 1000
};

var heartCheck = {
	timeout: 1000 * 60,        //1分钟发一次心跳
	timeoutObj: null,
	serverTimeoutObj: null,
	reset: function(){
		clearTimeout(this.timeoutObj);
		clearTimeout(this.serverTimeoutObj);
		return this;
	},
	start: function(){
		var self = this;
		this.timeoutObj = setTimeout(function(){
		    //这里发送一个心跳，后端收到后，返回一个心跳消息，
		    //onmessage拿到返回的心跳就说明连接正常
		    client.publish(`/devices/heart`,clientId);
		    self.serverTimeoutObj = setTimeout(function(){//如果超过一定时间还没重置，说明后端主动断开了
		      client.end();
		      client.reconnect();
		    }, self.timeout)
		}, this.timeout)
  },
}

// 开启订阅（登录成功后调用）
export const startSub = () => {
  client = mqtt.connect('wss://gppower.cn/mqtt',options);

  client.on('connect', () => {
		heartCheck.reset().start();      //心跳检测重置
		client.subscribe("msgNotice");
		client.subscribe("/devices/hearted");
		client.subscribe("/devices/pub/#");

    console.log('链接mqtt成功，并已订阅相关主题')
  });
  client.on('error', err => {
		heartCheck.reset();      //心跳检测重置
    console.log('链接mqtt报错', err);
    client.end();
    client.reconnect();
  });
  client.on('message', (topic, message) => {
		heartCheck.reset().start();      //心跳检测重置
    let reqData;
    try {
      reqData = { query: JSON.parse(message) };
    } catch (err) {
      reqData = { query: message.toString() };
    }
    if (topic + '' === 'msgNotice') { // 消息类通知主题
      Notification({
        title: '通知',
        type: 'success',
        message: reqData.query.msg
      })
    } else { // 设备相关主题
      const devId = topic.substring(9)
      const arg = {
        devId,
        msg: reqData.query
      }
      console.log('收到设备上传消息：', arg)
      // if(arg.devId=="device") this.$store.openled = arg.msg
    }
  });

  //监听  重连
  client.on('reconnect', function () {
		heartCheck.reset();      //心跳检测重置
    console.log('重连 Reconnecting...');
  });
  //监听  断开连接以后
  client.on('close', function () {
		heartCheck.reset();
    console.log('客户端 断开连接以后 close');
  });
  //监听  服务器断开
  client.on('disconnect', function (packet) {
		heartCheck.reset();
    console.log("服务器 断开",packet);
  });
  //监听  客户端下线
  client.on('offline',  ()=> {
		heartCheck.reset();
    console.log('客户端 下线 offline');
  });

  Vue.prototype.$mqtt = client; // 方便在vue组件中可以直接使用this.$mqtt -> client
  Vue.prototype.$mqttStore = clientStore; // 方便在vue组件中可以直接使用this.$mqtt -> client
}

// 关闭订阅（退出登录时调用）
export const closeSub = () => {
  client && client.end()
}
